export default {
  settings: 'Настройки',
  orderStateChaneNotifications: 'Получать уведомления при смене статуса заявки на:',
  limitNotificationByService: 'Ограничить уведомления по услугам',
  onlyOwnOrders: 'Только свои заявки',
  allOrders: 'Все заявки',
  deliveryService: 'Служба доставки',
  courierDeliveryToWarehouse: 'Курьерская доставка на склад',
  courierDelivery: 'Курьерская доставка',
  trackingNumber: 'Трекинг номер',
  warehouseAccounting: 'Приемка',
  warehouse: 'Склад',
  Request: 'Заявка',
  'on request': 'по заявке',
  'In progress': 'В работе',
  'Copy request': 'Скопировать заявку',
  'Create word application': 'Создать word заявку',
  'General information': 'Основная информация',
  Manager: 'Менеджер',
  transportirManager: 'Менеджер TRANSPORTIR',
  Agreement: 'Договор',
  uploadFile: 'Загрузить файл',
  Service: 'Услуга',
  Services: 'Услуги',
  costOfServices: 'Стоимость услуг',
  selectService: 'Выберите услугу',
  Customs: 'Таможня',
  'Customs price': 'Стоимость таможни',
  'Customs dispatch': 'Таможня отправления',
  'Customs delivery': 'Таможня доставки',
  'Customs registration': 'Таможенное оформление',
  delete: 'Удалить',
  cancel: 'Отмена',
  newOrder: 'Новая заявка',
  customsObject: {
    departure: 'отправления',
    arrival: 'назначения',
    export: 'отправления',
    import: 'назначения',
  },
  Cargo: 'Груз',
  information: 'Информация',
  fileInColdStorage: 'Файл заархивирован, обратитесь к менеджеру.',
  fileCode: 'Код файла',
  'Cargo info': 'Информация по грузу',
  'Declared value': 'Заявленная стоимость',
  ADR: 'АДР',
  Currency: 'Валюта',
  Freight: 'Фрахт',
  secondaryFreight: 'Второй фрахт',
  'Freight price': 'Стоимость фрахта',
  'Postal code': 'Индекс',
  Address: 'Адрес',
  Contacts: 'Контакты',
  Insurance: 'Страховка',
  'Insurance price': 'Стоимость страховки',
  Responsibility: 'Ответвственность',
  Dispatch: 'Отправка',
  dispatchTerms: 'Условия отправки',
  Delivery: 'Доставка',
  deliveryTerms: 'Условия доставки',
  Shipper: 'Отправитель',
  Consignee: 'Получатель',
  Date: 'Дата',
  'Expected date': 'Ожидаемая дата',
  Country: 'Страна',
  'Origin country': 'Страна происхождения',
  city: 'Город',
  Route: 'Маршрут',
  Loading: 'Загрузка',
  'Loading number': 'Погрузочный номер',
  'Loading methods': 'Методы загрузки',
  Unloading: 'Разгрузка',
  'Unloading methods': 'Методы выгрузки',
  Description: 'Описание',
  Weight: 'Вес',
  'Weight, t.': 'Вес, тн',
  Tons: 'Тонн',
  Volume: 'Объем',
  cbm: 'М³',
  volumeCbm: 'Объем, М³',
  Price: 'Стоимость',
  ItemsShort: 'Шт',
  'Cargo places': 'Количество мест',
  'Thermal requirements': 'Температурный режим',
  Min: 'Мин',
  Max: 'Макс',
  Comment: 'Комментарий',
  Point: 'Пункт',
  Details: 'Детали',
  Tracking: 'Трекинг',
  Name: 'Наименование',
  'Quick search': 'Быстрый поиск',
  'Loading date': 'Дата загрузки',
  Requests: 'Заявки',
  'All requests': 'Все заявки',
  Clear: 'Очистить',
  Bill: 'Счет',
  Company: 'Компания',
  'Unpaid bills': 'Неоплаченные счета',
  'Bills to pay': 'Счета к оплате',
  'Await for payment': 'Ожидается к оплате',
  'Payment due': 'Срок оплаты',
  'Booker docs': 'Бух. документы',
  'Booker documents': 'Бухгалтерские документы',
  'Financial documents': 'Финансовые документы',
  Files: 'Файлы',
  'Drag files': 'Перетяните файлы',
  Approve: 'Подтвердить',
  'Approve from your side required': 'Требуется подтверждение с вашей стороны',
  'Manager approvement required': 'Требует подтверждения менеджера',
  Total: 'Всего',
  Sum: 'Сумма',
  Showing: 'Показано',
  from: 'от',
  Save: 'Сохранить',
  Saved: 'Сохранено',
  Close: 'Закрыть',
  Visibility: 'Видимость',
  Grouping: 'Группировка',
  Direction: 'Направление',
  Expiration: 'Просрочка',
  anyMultiple: 'Любые',
  days: 'дней',
  notSpecifiedMultiple: 'Не указаны',
  'Export to excel': 'Экспортировать в excel',
  'Load more': 'Загрузить еще',
  validation: {
    messages: {
      _default: 'Поле заполнено некорректно.',
      'This value should not be blank.': 'Значение не должно быть пустым.',
      required: 'Значение не должно быть пустым.',
      Disabled: 'Выключено',
      'This date cannot be in the past.': 'Эта дата не может быть в прошлом.',
      'This region does not have active price collection.': 'Этот регион не имеет активного набора цен.',
      'Reason required.': 'Причина не может быть пустой.',
      'This status cannot be set manually.': 'Этот статус не может быть установлен в ручную.',
      'No currency rates for selected date.': 'На выбранную дату нет курсов валют.',
      'Order not found': 'Заказ не найден',
      'This value is not a valid email address.': 'Значение адреса электронной почты недопустимо.',
      'Cannot be extended for more than a year.': 'Не может быть продлено больше чем на год.',
      'Cannot set this value to credit limit.': 'Недопустимое значение кредитного лимита.',
      'You must select at least one choice.': 'Необходимо выбрать хотя бы одно значение.',
      'Duplicate value.': 'Повторяющееся значение.',
      'Incorrect value.': 'Некоректное значение.',
      similarAddressData: 'Данные этого поля дублируют другие поля адреса.',
      'Can not contain cyrillic chars': 'Кириллические символы запрещены',
      'This value is not valid.': 'Поле заполнено некорректно.',
    },
  },
  api_errors: {
    'Validation Failed': 'Ошибка валидации',
    'Access Denied.': 'Доступ запрещен.',
    'Selected legal entity has no application template.': 'Выбранное юр.лицо не имеет шаблона заявки.',
  },
};
