<template>
  <v-sheet>
    <v-row no-gutters
           align="center"
           class="flex-nowrap caption text--primary">
      <v-col class="text-ellipsis">
        <v-row no-gutters>
          <v-col cols="12">
            {{file.description}}
          </v-col>
          <v-col class="text-no-wrap flex-grow-0 text--secondary pl-1">
            {{file.uploadDateTime|dateTimeShort}}
          </v-col>
          <v-spacer />
          <v-col class="text-no-wrap flex-grow-0 text--secondary">
            {{file.extension}}, {{file.sizeInBytes|fileSize}}
          </v-col>
        </v-row>
      </v-col>
      <v-col class="flex-grow-0 pl-1">
        <file-download-btn :download-link="downloadLink"
                           :file-object="file"
                           color="success"
                           icon>
          <v-icon>
            mdi-cloud-download
          </v-icon>
        </file-download-btn>
      </v-col>
    </v-row>
    <v-divider />
  </v-sheet>
</template>

<script>
import FileDownloadBtn from '@/components/File/FileDownloadBtn.vue';

export default {
  name: 'FileItem',
  components: {FileDownloadBtn},
  props: {
    file: {
      type: Object,
      required: true,
    },
    downloadLink: {
      type: String,
      required: true,
    },
  },
  data () {
    return {};
  },
};
</script>

<style lang="sass"
       scoped>

</style>
