<template>
  <v-btn v-if="!fileObject.coldStorage"
         :href="downloadLink"
         v-bind="$attrs">
    <slot />
  </v-btn>
  <file-in-cold-storage-dialog v-else
                               :file-id="fileObject.id">
    <template v-slot:activator="{open}">
      <v-btn v-bind="$attrs"
             :class="$vnode.data.staticClass"
             @click="open">
        <slot />
      </v-btn>
    </template>
  </file-in-cold-storage-dialog>
</template>

<script>
import FileInColdStorageDialog from '@/components/File/FileInColdStorageDialog.vue';

export default {
  name: 'FileDownloadBtn',
  components: {FileInColdStorageDialog},
  props: {
    fileObject: {
      type: Object,
      required: true,
    },
    downloadLink: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>

</style>
