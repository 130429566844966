<template>
  <dialog-wrapper :title="$t('information')">
    <template v-slot:activator="{open}">
      <slot name="activator"
            :open="open" />
    </template>
    <v-alert class="mt-4 mb-2"
             type="info"
             icon="mdi-information-outline"
             text>
      <div class="text--primary">
        {{$t('fileInColdStorage')}}
        <br>
        {{$t('fileCode')}}: <span class="warning--text">{{fileId|id}}</span>
      </div>
    </v-alert>
    <template v-slot:actions="{close}">
      <v-btn @click="close">
        {{$t('Close')}}
      </v-btn>
    </template>
  </dialog-wrapper>
</template>

<script>
export default {
  name: 'FileInColdStorageDialog',
  props: {
    fileId: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style scoped lang="sass">

</style>
